body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
  background-color: rgb(14, 28, 39) !important;
  color: white;
  font-family: Arial, sans-serif;
}

.fleet-tracking-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}

.game-menu {
  display: flex;
  justify-content: flex-end;
  background-color: #2c3e50;
  padding: 10px;
}

.game-menu button {
  background-color: transparent;
  border: none;
  color: white;
  padding: 5px 15px;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.game-menu button:hover,
.game-menu button.active {
  background-color: #34495e;
}

.content-wrapper {
  display: flex;
  height: calc(100vh - 50px); /* Adjust based on game-menu height */
}

.sidebar {
  width: 300px;
  background-color: #2c3e50;
  display: flex;
  flex-direction: column;
}

.sidebar h2 {
  margin: 0;
  padding: 20px;
  background-color: #34495e;
}

.driver-list {
  flex-grow: 1;
  overflow-y: auto;
}

.driver-item {
  padding: 10px;
  border-bottom: 1px solid #34495e;
  cursor: pointer;
}

.driver-item:hover {
  background-color: #34495e;
}

.driver-item > div:first-child {
  display: flex;
  justify-content: space-between;
}

.driver-item span:first-child {
  font-weight: bold;
}

.progress-bar {
  height: 5px;
  background-color: #34495e;
  margin-top: 10px;
}

.progress {
  height: 100%;
  background-color: #2ecc71;
}

.job-info {
  font-size: 0.9em;
  margin-top: 10px;
}

.job-info div {
  margin-bottom: 5px;
}

.job-info i {
  margin-right: 5px;
  width: 20px;
  text-align: center;
}

#mapts {
  flex-grow: 1;
  background-color: rgb(14, 28, 39) !important;
}

.player .circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transition: background-color 0.7s ease;
}

/* Custom scrollbar styles for the driver list */
.driver-list::-webkit-scrollbar {
  width: 6px;
}

.driver-list::-webkit-scrollbar-track {
  background: #2c3e50;
}

.driver-list::-webkit-scrollbar-thumb {
  background-color: #34495e;
  border-radius: 3px;
}

/* For Firefox */
.driver-list {
  scrollbar-width: thin;
  scrollbar-color: #34495e #2c3e50;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .content-wrapper {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    height: 200px;
  }

  #mapts {
    height: calc(100vh - 250px); /* Adjust based on sidebar and game-menu height */
  }
}